import SiteButton_GamingButtonComponent from '../components/SiteButton/viewer/skinComps/GamingButton/GamingButton.skin';
import SiteButton_GamingButtonController from '../components/SiteButton/viewer/SiteButton.controller';


const SiteButton_GamingButton = {
  component: SiteButton_GamingButtonComponent,
  controller: SiteButton_GamingButtonController
};


export const components = {
  ['SiteButton_GamingButton']: SiteButton_GamingButton
};

